import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import { TeamMemberProps, TeamProps } from "home/components/page_sections_updated/PageSectionTeam";
import { section_my } from "home/components/shared/pagesCfg";
import Image from "next/legacy/image";
const Member = ({
  name,
  role,
  image
}: TeamMemberProps) => {
  return <Flex direction="column" data-sentry-element="Flex" data-sentry-component="Member" data-sentry-source-file="PageSectionTeamDefault.tsx">
      <Box pos="relative" w={["100%", "375px", "315px", "290px", "291px"]} h={{
      base: "320px",
      md: "315px",
      lg: "290px",
      xl: "291px"
    }} data-sentry-element="Box" data-sentry-source-file="PageSectionTeamDefault.tsx">
        <Image priority src={image.data.attributes.url} layout="fill" objectFit="cover" alt="profile" data-sentry-element="Image" data-sentry-source-file="PageSectionTeamDefault.tsx" />
      </Box>
      <Flex direction="column" px={2} maxW={["100%", "375px", "315px", "290px", "291px"]} data-sentry-element="Flex" data-sentry-source-file="PageSectionTeamDefault.tsx">
        <Heading mt={4} mb={2} size="sm" data-sentry-element="Heading" data-sentry-source-file="PageSectionTeamDefault.tsx">
          {name}
        </Heading>
        <Text textStyle="subtitleMedium" color="blueGray.500" data-sentry-element="Text" data-sentry-source-file="PageSectionTeamDefault.tsx">
          {role}
        </Text>
      </Flex>
    </Flex>;
};
export default function PageSectionTeam({
  title,
  members
}: TeamProps) {
  return <Box my={section_my} mx="auto" maxW="1325px" data-sentry-element="Box" data-sentry-component="PageSectionTeam" data-sentry-source-file="PageSectionTeamDefault.tsx">
      <Box pb={{
      base: 10,
      md: 12
    }} textAlign="center" data-sentry-element="Box" data-sentry-source-file="PageSectionTeamDefault.tsx">
        <Heading size="md" data-sentry-element="Heading" data-sentry-source-file="PageSectionTeamDefault.tsx">{title}</Heading>
      </Box>
      <Flex direction={{
      base: "column",
      md: "row"
    }} wrap={{
      md: "wrap"
    }} gap={{
      base: 10,
      md: 12
    }} justify={{
      md: "center"
    }} data-sentry-element="Flex" data-sentry-source-file="PageSectionTeamDefault.tsx">
        {members.map(({
        id,
        name,
        role,
        image
      }) => <Box key={id} className="boxit" alignSelf={{
        sm: "center",
        md: "flex-start"
      }}>
            <Member id={id} name={name} role={role} image={image} />
          </Box>)}
      </Flex>
    </Box>;
}