import React, { memo } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Image as Img } from "@chakra-ui/react";
import ButtonManaged from "home/components/shared/ButtonManaged";
import { CMSButton } from "home/components/shared/CmsTypes";
import { PageSectionCardImageTag } from "./sharedTypes";
type CardContentProps = {
  tag: PageSectionCardImageTag | null;
  title: string;
  body: string;
  buttons: CMSButton[];
  right?: boolean;
};

/**
 * Handles displaying the content within the PageSectionCardImage component, with the appropriate styling and layout.
 *
 * @param {PageSectionCardImageTag | null} tag - The tag (PageSectionCardImageTag) to be displayed in the card.
 * @param {string} title - The title to be displayed in the card.
 * @param {string} body - The body text to be displayed in the card.
 * @param {CMSButton[]} buttons - The buttons to be displayed in the card. Accepts an empty array.
 * @param {boolean} [right=false] - Whether the content should be displayed on the right side of the card.
 */
const CardContent = memo(function CardContent({
  tag,
  title,
  body,
  buttons,
  right = false
}: CardContentProps) {
  const svg = tag ? tag.icon.data?.attributes.url : null;
  const alt = tag ? tag.icon.data?.attributes.alternativeText : "card image";
  return <Box ml={{
    lg: !right ? -20 : 0
  }} mr={{
    lg: right ? -20 : 0
  }} mt={{
    base: -16,
    lg: "auto"
  }} mb={{
    lg: "auto"
  }} px={{
    base: 4,
    lg: 0
  }} py={{
    lg: "auto"
  }} w={{
    lg: "lg",
    xl: "2xl"
  }} zIndex={1}>
      <Box py={8} px={{
      base: 4,
      md: 8
    }} shadow="3xl" bg="white" position="relative" // implicit z-index to move it
    >
        {tag && <Flex display="inline-flex" mb={5} p={1} gap={2} bg="cyan.50">
            {svg ? <Img src={svg} alt={alt} boxSize={6} /> : null}
            <Text casing="uppercase" textStyle="bodyBold" color="cyan.700">
              {tag.label}
            </Text>
          </Flex>}

        <Flex direction="column" justify="flex-start" gap={1}>
          <Heading size="md">{title}</Heading>
          <Text noOfLines={{
          base: 5,
          lg: tag ? 2 : 3,
          xl: tag ? 3 : 4
        }} textStyle="subtitleNormal">
            {body}
          </Text>
        </Flex>

        <Flex direction={{
        base: "column",
        md: "row"
      }} gap={{
        md: 6
      }}>
          {buttons.map(item => <ButtonManaged key={item.id} setup={item} mt={6} w={["100%", "initial"]} overflow="hidden" />)}
        </Flex>
      </Box>
    </Box>;
});
export { CardContent };