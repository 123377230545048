import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import ButtonManaged from "home/components/shared/ButtonManaged";
import { CMSButton } from "home/components/shared/CmsTypes";
import { section_my } from "home/components/shared/pagesCfg";
interface ContentTextProps {
  title?: string;
  body: string;
  button?: CMSButton;
}
export default function PageSectionContentText({
  title,
  body,
  button
}: ContentTextProps) {
  return <Box my={section_my} mx={[4, null, 8, 16]} data-sentry-element="Box" data-sentry-component="PageSectionContentText" data-sentry-source-file="PageSectionContentText.tsx">
      <Flex direction="column" textAlign="left" maxW="936px" ml={{
      xl: "8%",
      "2xl": "15%"
    }} data-sentry-element="Flex" data-sentry-source-file="PageSectionContentText.tsx">
        {title && <Box mb={6} maxW="613px">
            <Heading size="md">{title}</Heading>
          </Box>}
        <Box data-sentry-element="Box" data-sentry-source-file="PageSectionContentText.tsx">
          <Text textStyle="titleSm" data-sentry-element="Text" data-sentry-source-file="PageSectionContentText.tsx">{body}</Text>
        </Box>
        {button && <Box mt={8}>
            <ButtonManaged setup={button} w={{
          base: "100%",
          md: "initial"
        }} />
          </Box>}
      </Flex>
    </Box>;
}