import { Box, Text, Heading, Flex, Image, VStack, Grid, GridItem, Button } from "@chakra-ui/react";
import MapBox from "home/components/shared/MapBox";
import { getKey } from "home/lib/utils";
import { section_my } from "home/components/shared/pagesCfg";
import { useState } from "react";
interface FacilityProps {
  icon: Record<string, any>;
  description: string;
}
interface FacilitiesProps {
  title: string;
  location: Record<string, any>;
  items: Array<FacilityProps>;
}
const Facilities = ({
  icon,
  description
}: FacilityProps) => {
  const svg = icon?.data?.attributes.url;
  const alt = icon?.data?.attributes.alternativeText || "item image";
  return <Flex h={8} data-sentry-element="Flex" data-sentry-component="Facilities" data-sentry-source-file="PageSectionFacilities.tsx">
      {icon?.data && <Image src={svg} alt={alt} boxSize={8} p={1} mr={4} />}
      <Text textStyle="subtitleNormal" lineHeight="taller" data-sentry-element="Text" data-sentry-source-file="PageSectionFacilities.tsx">
        {description}
      </Text>
    </Flex>;
};
export default function PageSectionFacilities({
  title,
  location,
  items
}: FacilitiesProps) {
  const [showAll, setShowAll] = useState(false);
  return <VStack my={section_my} flexDirection={"column"} px={{
    base: 4,
    md: 10,
    lg: 24
  }} mx="auto" maxW="container.xl" data-sentry-element="VStack" data-sentry-component="PageSectionFacilities" data-sentry-source-file="PageSectionFacilities.tsx">
      <Box mb={[10, 10, 0]} w={["100%"]} textAlign="left" alignSelf={{
      md: "flex-start"
    }} data-sentry-element="Box" data-sentry-source-file="PageSectionFacilities.tsx">
        <Heading mb={6} size="md" data-sentry-element="Heading" data-sentry-source-file="PageSectionFacilities.tsx">
          {title}
        </Heading>

        <Grid templateColumns={["1fr", null, "repeat(2, 1fr)", null, "repeat(3, 1fr)"]} gap={4} mb={10} data-sentry-element="Grid" data-sentry-source-file="PageSectionFacilities.tsx">
          {items.map(({
          icon,
          description
        }, i) => <GridItem key={getKey()} display={[showAll || i < 5 ? "block" : "none", null, "block"]}>
              <Facilities icon={icon} description={description} />
            </GridItem>)}
        </Grid>

        <Button variant={"outline"} colorScheme={"cyan"} cursor={"pointer"} display={["block", null, "none"]} width={"100%"} onClick={() => setShowAll(!showAll)} data-sentry-element="Button" data-sentry-source-file="PageSectionFacilities.tsx">
          {showAll ? "Vis mindre" : "Vis alle"}
        </Button>
      </Box>
      <Box w={["100%"]} h={["321px", null, null, "390px"]} data-sentry-element="Box" data-sentry-source-file="PageSectionFacilities.tsx">
        {location && <MapBox location={location} />}
      </Box>
    </VStack>;
}