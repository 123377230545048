import { Stack, Box, Text, Center, Flex, Heading } from "@chakra-ui/react";
import { section_mt, section_mb, general_py, overlay_def_mt } from "home/components/shared/pagesCfg";
interface CardStatsProps {
  id: number;
  label: string;
  value: string;
}
const CardStat = ({
  label,
  value
}: CardStatsProps) => {
  return <Stack data-sentry-element="Stack" data-sentry-component="CardStat" data-sentry-source-file="PageSectionCardStats.tsx">
      <Center data-sentry-element="Center" data-sentry-source-file="PageSectionCardStats.tsx">
        <Heading color="cyan.700" size="md" data-sentry-element="Heading" data-sentry-source-file="PageSectionCardStats.tsx">
          {label}
        </Heading>
      </Center>
      <Center data-sentry-element="Center" data-sentry-source-file="PageSectionCardStats.tsx">
        <Text textStyle="titleSm" color={"gray.500"} data-sentry-element="Text" data-sentry-source-file="PageSectionCardStats.tsx">
          {value}
        </Text>
      </Center>
    </Stack>;
};
export default function PageSectionCardStats({
  stats,
  overlay
}: {
  stats: Array<CardStatsProps>;
  overlay: boolean;
}) {
  return <Box mx={{
    base: overlay ? 6 : 0,
    sm: overlay ? 6 : 4,
    md: 6,
    lg: 12
  }} mt={overlay ? overlay_def_mt : section_mt} mb={section_mb} data-sentry-element="Box" data-sentry-component="PageSectionCardStats" data-sentry-source-file="PageSectionCardStats.tsx">
      <Center data-sentry-element="Center" data-sentry-source-file="PageSectionCardStats.tsx">
        <Box maxW="container.lg" bg="white" w="100%" py={general_py} px={{
        base: 6,
        xl: 8
      }} shadow="3xl" zIndex={1} data-sentry-element="Box" data-sentry-source-file="PageSectionCardStats.tsx">
          <Flex gap={[4, 5]} wrap="wrap" direction="row" align="center" justify="center" data-sentry-element="Flex" data-sentry-source-file="PageSectionCardStats.tsx">
            {stats.map((item: CardStatsProps) => <Center key={item.id} flexBasis={{
            base: "100%",
            sm: "calc(50% - 12px)",
            md: "calc(33.333333% - 16px)",
            lg: "calc(25% - 18px)"
          }}>
                <CardStat id={item.id} label={item.label} value={item.value} />
              </Center>)}
          </Flex>
        </Box>
      </Center>
    </Box>;
}