import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import { TeamMemberProps, TeamProps } from "home/components/page_sections_updated/PageSectionTeam";
import { section_my } from "home/components/shared/pagesCfg";
import Image from "next/legacy/image";
const Member = ({
  image,
  name,
  role,
  body
}: TeamMemberProps) => {
  return <Flex direction={{
    base: "column",
    md: "row"
  }} w={["100%", "375px", "100%"]} gap={{
    md: 8
  }} data-sentry-element="Flex" data-sentry-component="Member" data-sentry-source-file="PageSectionTeamExtended.tsx">
      <Box pos="relative" w={{
      md: "315px",
      lg: "386px",
      xl: "506px"
    }} h={["346px", null, "315px", "386px", "506px"]} data-sentry-element="Box" data-sentry-source-file="PageSectionTeamExtended.tsx">
        <Image src={image.data.attributes.url} layout="fill" objectFit="cover" alt="profile" data-sentry-element="Image" data-sentry-source-file="PageSectionTeamExtended.tsx" />
      </Box>
      <Flex w={{
      md: "calc(100% - 347px)",
      lg: "calc(100% - 418px)",
      xl: "calc(100% - 538px)"
    }} direction="column" px={[4, null, 0]} h={{
      md: "315px",
      lg: "386px",
      xl: "506px"
    }} justify={{
      md: "center",
      lg: "flex-end"
    }} overflow="hidden" data-sentry-element="Flex" data-sentry-source-file="PageSectionTeamExtended.tsx">
        <Heading size="sm" mt={[4, null, 0]} mb={[2, null, 0]} data-sentry-element="Heading" data-sentry-source-file="PageSectionTeamExtended.tsx">
          {name}
        </Heading>
        <Text mb={6} textStyle="subtitleMedium" color="blueGray.500" data-sentry-element="Text" data-sentry-source-file="PageSectionTeamExtended.tsx">
          {role}
        </Text>
        {body && <Text overflow="hidden" whiteSpace="pre-line" textStyle="subtitleNormal">
            {body}
          </Text>}
      </Flex>
    </Flex>;
};
export default function PageSectionTeamExtended({
  title,
  members
}: TeamProps) {
  return <Box my={section_my} px={{
    md: 5
  }} maxW="container.xl" mx="auto" data-sentry-element="Box" data-sentry-component="PageSectionTeamExtended" data-sentry-source-file="PageSectionTeamExtended.tsx">
      <Box textAlign="center" data-sentry-element="Box" data-sentry-source-file="PageSectionTeamExtended.tsx">
        <Heading pb={[10, null, 20]} size="md" data-sentry-element="Heading" data-sentry-source-file="PageSectionTeamExtended.tsx">
          {title}
        </Heading>
      </Box>
      <Flex direction="column" gap={10} sx={{
      "@media screen and (min-width: 48em)": {
        "& div:nth-of-type(even) div:first-of-type": {
          order: 1
        },
        gap: 20
      }
    }} data-sentry-element="Flex" data-sentry-source-file="PageSectionTeamExtended.tsx">
        {members.map(({
        id,
        image,
        name,
        role,
        body
      }) => <Flex justify={{
        sm: "center"
      }} key={id}>
            <Member id={id} image={image} name={name} role={role} body={body} />
          </Flex>)}
      </Flex>
    </Box>;
}