import { Center, Flex, Heading, Text, Box } from "@chakra-ui/react";
import ButtonManaged from "home/components/shared/ButtonManaged";
import { CMSButton } from "home/components/shared/CmsTypes";
import { section_my, general_py } from "home/components/shared/pagesCfg";
interface CTAProps {
  title: string;
  body: string;
  button: CMSButton;
}
export default function PageSectionCTA({
  title,
  body,
  button
}: CTAProps) {
  return <Center my={section_my} px={[4, 6, 10]} maxW="container.lg" mx="auto" data-sentry-element="Center" data-sentry-component="PageSectionCTA" data-sentry-source-file="PageSectionCTA.tsx">
      <Flex direction="column" justify="center" alignItems="center" py={general_py} data-sentry-element="Flex" data-sentry-source-file="PageSectionCTA.tsx">
        <Box textAlign="center" mb={8} data-sentry-element="Box" data-sentry-source-file="PageSectionCTA.tsx">
          <Heading size="md" mb={4} data-sentry-element="Heading" data-sentry-source-file="PageSectionCTA.tsx">
            {title}
          </Heading>
          <Text textStyle="subtitleNormal" data-sentry-element="Text" data-sentry-source-file="PageSectionCTA.tsx">{body}</Text>
        </Box>

        <Box data-sentry-element="Box" data-sentry-source-file="PageSectionCTA.tsx">
          <ButtonManaged setup={button} data-sentry-element="ButtonManaged" data-sentry-source-file="PageSectionCTA.tsx" />
        </Box>
      </Flex>
    </Center>;
}